/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-31 10:50:26
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-07 17:26:49
 */
export const columns = [
// {
//     title: 'id',
//     dataIndex: 'id',
//     key: 'id',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
{
  title: '供应商全称',
  dataIndex: 'supplierName',
  key: 'supplierName',
  align:'left',
},
{
  title: '供应商简称',
  dataIndex: 'name',
  key: 'name',
  align:'center',
},
{
  title: '供应商编码',
  dataIndex: 'supplierCode',
  key: 'supplierCode',
  align:'center',
},
{
  title: 'K3编码',
  dataIndex: 'k3Code',
  key: 'k3Code',
  align:'center',
},
{
  title: '供应商折扣',
  dataIndex: 'supplierDiscount',
  key: 'supplierDiscount',
  align:'center',
},
{
  title: '开户银行',
  dataIndex: 'depositBank',
  key: 'depositBank',
  align:'left',
},
{
  title: '银行账户',
  dataIndex: 'bankAccount',
  key: 'bankAccount',
  align:'center',
},
{
  title: '创建时间',
  dataIndex: 'createTime',
  key: 'createTime',
  align:'center',
},
]
